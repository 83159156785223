.footer {
    @include padd(both, half);

    background: $black;
    color: $white;

    &__wrapper {
        display: flex;
        justify-content: center;
        flex-direction: column;

        @include mq(md) {
            justify-content: space-between;
            flex-direction: row;
        }
    }

    &__logo {
        @include trans;
        position: relative;
        z-index:5;
        flex-basis:100%;
        text-align: center;

        @include mq(sm) {
            text-align: left;
        }

        a {
            display: inline-block;
        }

        svg {
            fill: $white;
            width: 86px;
            height: 21px;
        }

        @include mq(md) {
            flex-basis:30%;

        }
    }

    &__nav,
    &__social {
        display: flex;
        flex-grow: 1;

        li {
            margin: 10px;

            @include mq(md){
                margin: 0 10px;
            }
        }

        a {
            color: $white;
            font-size: 14px;
            font-weight: 500;
        }
    }

    &__nav {
        justify-content: center;
        flex-direction: column;
        text-align: center;
        padding: 20px 0;

        @include mq(md) {
            flex-basis: 30%;
            justify-content: space-between;
            flex-direction: row;
            padding: 0;
        }
    }

    &__social {
        justify-content: center;
        flex-direction: row;

        i {
            font-size: 21px;
        }

        @include mq(md){
            justify-content: flex-end;
        }
    }

    &__copy {
        font-size: 12px;
    }
}
