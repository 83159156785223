@mixin section-title($font-size:48px, $color: $darkestGray){
    font-size: $font-size / 1.5;
    line-height: 1;
    margin: 0;
    padding-bottom: 20px;
    color: $color;

    @include mq(md){
        font-size: $font-size;
    }
}

@mixin text($font-size:20px, $color: $darkGray){
    font-size: $font-size / 1.3;
    line-height: 32px;
    margin: 0 0 20px;
    color: $color;

    @include mq(md){
        font-size: $font-size;
    }
}

.home {

    section {
        position: relative;

        .container {
            position: relative;
            z-index: 1;
        }
    }

    &__img-col {
        padding-bottom: 20px;

        @include mq(sm) {
            padding-bottom: 0;
        }
    }

    &__stage {
        display: none;

        @include mq(md) {
            display: block;
            position: absolute;
            top: 0;
            left:0;
            right:0;
            bottom:0;
            z-index: 0;
            overflow: hidden;
        }

        ._semicircle {
            position: absolute;
            width: 10vw;
            height: 10vw;
            background: url('/images/home/semi-circle.png') no-repeat center center / contain;

            &.-small {
                width: 5vw;
                height: 5vw;
                background: url('/images/home/semi-circle-small.png') no-repeat center center / contain;
            }
        }

        ._donut,
        ._circle {
            font-weight: 700;
            font-size: 80vw;
            @include gradient($purple, $pink);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            position: absolute;
            top: -50%;
            line-height: 0.9;
            left: 0;
            right:0;
            text-align: center;
        }

        ._circle {
            font-size: 30vw;

            &.-small {
                font-size: 15vw;
            }
        }

        ._red {
            display: flex;
            border-radius: 50%;
            width: 100px;
            height:100px;
            background: $white;
            box-shadow: 0 10px 15px 0 rgba(0,0,0,.05);
            position: absolute;
            justify-content: center;
            align-items: center;

            i {
                font-size: 48px;
                color: $lightGray;
            }
        }

        ._tablet {
            position: absolute;
            left: 55%;
        }

        ._gicon {
            width: 20vw;
            height: 20vw;
            background: url('/images/home/g-logo.png') no-repeat center center / cover;
            position: absolute;
            right: 0;
            bottom:-15%;
            opacity: .3;
        }
    }

    &__hero {
        @include gradient($pink, $purple);
        @include padd;

        color: $white;
        text-align: center;
        margin-bottom: 150px;

        .container {
            //@include padd(top);
        }

        &__subtitle {
            font-size: 24px;
            display: block;
            padding-bottom: 20px;
        }

        &__title {
            font-size: 28px;
            line-height: 36px;
            margin: 0;
            padding-bottom: 20px;

            @include mq(md){
                font-size: 52px;
                line-height: 60px;
            }
        }

        &__text {
            font-size: 16px;
            padding-bottom: 20px;

            @include mq(sm) {
                font-size: 20px;
            }
        }

        &__actions{
            a {
                margin: 0 7px;
            }
        }

        &__player {
            padding-top: 50px;
            margin-bottom: -15vw;

            &__wrapper {
                width: 80%;
                margin: auto;
                position: relative;

                &:before,
                &:after {
                    content: '';
                    position: absolute;
                    height: 10px;
                    border-radius: 0 0 10px 10px;
                    background: #5a52b6;
                    bottom:-10px;
                    left: 20px;
                    right:20px;
                }

                &:after {
                    bottom: -20px;
                    left:40px;
                    right:40px;
                    background: $purple;
                }
            }

            &__video {
                width:100%;
            }
        }
    }

    &__how {
        @include padd;
        background: $white;
        text-align: center;

        .home__stage {
            ._circle {
                right: -12vw;
                top: 5%;
                left:auto;

                &.-small {
                    left: -7vw;
                    top: 70%;
                    right: auto;
                }
            }
        }

        &__title {
            @include section-title;
        }

        &__text {
            @include text;
        }

        &__icons {
            display: flex;
            justify-content: space-evenly;
            padding: 50px 0;
            flex-direction: column;

            @include mq(md){
                flex-direction: row;
            }
        }

        &__icon {
            text-align: center;
            &__img {
                display: inline-block;
                border-radius: 50%;
                width: 200px;
                height:200px;
                background: $white;
                box-shadow: 0 15px 20px 0 rgba(0,0,0,.05);
                position: relative;
                margin-bottom: 40px;

                img {
                    position: absolute;
                    width: 100px;
                    top:50%;
                    left:50%;
                    transform: translate3d(-50%,-50%,0);
                }
            }

            &__text {
                display: block;
                font-weight: 500;
                color: $darkestGray;
                text-transform: capitalize;
            }
        }

        &__subtitle {
            @include section-title(32px);
            color: $purple;
            font-weight: 300;
        }
    }

    &__gpartners {
        @include padd;

        background: $darkGray;
        color: $white;
        text-align: center;

        @include mq(sm) {
            text-align: left;
        }

        &__logo {
            margin-top: 100px;
            display: block;
        }
    }

    &__simple,
    &__profitable,
    &__content {
        @include padd;

        &__subtitle {
            color: $purple;
            font-weight: 500;
        }

        &__title {
            @include section-title;
            margin-bottom: 20px;
        }

        &__features {

        }

        &__feature {
            display: flex;
            margin-bottom: 40px;

            &__index {
                background: $purple;
                flex-basis: 32px;
                flex-shrink: 0;
                width: 32px;
                height: 32px;
                border-radius: 50%;
                display: flex;
                font-size: 18px;
                font-weight: 700;
                color: $white;
                margin-right: 20px;
                justify-content: center;
                align-items: center;
            }
        }
    }

    &__simple {
        padding-bottom: 0!important;

        .home__stage {
            &:hover {
                ._semicircle {
                    &:nth-child(1) {
                        top: 5%;
                        right:10%;
                        transform: rotate(0deg);
                    }

                    &:nth-child(2) {
                        left:-5%;
                        top: 30%;
                        width: 25vw;
                        height: 25vw;
                        transform: rotate(180deg);
                    }
                }
            }
            ._semicircle {
                @include trans(1s);

                &:nth-child(1) {
                    top: -40%;
                    right:-50%;
                    transform: rotate(90deg);
                }

                &:nth-child(2) {
                    left:-25%;
                    top: 30%;
                    width: 25vw;
                    height: 25vw;
                    transform: rotate(0deg);
                }
            }
        }

        .container {
            pointer-events: none;
        }
    }

    &__profitable,
    &__content {
        background: $lightestGray;
    }

    &__manage {
        @include padd;
        text-align: center;

        .container {
            pointer-events: none;
        }

        .home__stage {
            @include trans(1s);
            width:100%;
            margin: auto;
            max-width: 100vw;
            z-index: 2;

            &:hover {
                max-width: 1200px;

                ._red {
                    &:nth-child(1) {
                        opacity: 1;
                        transform: translate3d(0,0,0) rotate(0) scale3d(1,1,1);
                    }
                    &:nth-child(2) {
                        opacity: 1;
                        transform: translate3d(0,0,0) rotate(0) scale3d(1,1,1);
                    }
                    &:nth-child(3) {
                        opacity: 1;
                        transform: translate3d(0,0,0) rotate(0) scale3d(1,1,1);
                    }
                    &:nth-child(4) {
                        opacity: 1;
                        transform: translate3d(0,0,0) rotate(0) scale3d(1,1,1);
                    }
                    &:nth-child(5) {
                        opacity: 1;
                        transform: translate3d(0,0,0) rotate(0) scale3d(1,1,1);
                    }
                }
            }
            ._red {
                @include trans(1.5s);

                &:nth-child(1) {
                    opacity: 0;
                    left:15%;
                    top: 30%;
                    transform: translate3d(-50vw,-30vw,0) rotate(1080deg) scale3d(3,3,3);
                }
                &:nth-child(2) {
                    opacity: 0;
                    left:8.33%;
                    top: 50%;
                    transform: translate3d(-50vw,50vh,0) rotate(1080deg) scale3d(3,3,3);
                }
                &:nth-child(3) {
                    opacity: 0;
                    left:49%;
                    top: 32%;
                    transform: translate3d(0,-10vh,0) rotate(1080deg) scale3d(3,3,3);
                }
                &:nth-child(4) {
                    opacity: 0;
                    left:86.3%;
                    top: 25%;
                    transform: translate3d(50vw,-40vh,0) rotate(1080deg) scale3d(3,3,3);
                }
                &:nth-child(5) {
                    opacity: 0;
                    left:80%;
                    top: 45%;
                    transform: translate3d(50vw,40vh,0) rotate(1080deg) scale3d(3,3,3);
                }
            }
        }

        &__title {
            @include section-title;
            margin-bottom: 40px;
        }

        &__features {
            display: flex;
            justify-content: space-evenly;
            flex-direction: column;

            @include mq(md) {
                flex-direction: row;
            }
        }

        &__feature {
            &__icon {
                display: inline-block;
                border-radius: 50%;
                width: 200px;
                height:200px;
                background: $white;
                box-shadow: 0 15px 20px 0 rgba(0,0,0,.05);
                position: relative;
                margin-bottom: 40px;

                img {
                    position: absolute;
                    width: 100px;
                    top:50%;
                    left:50%;
                    transform: translate3d(-50%,-50%,0);
                }
            }

            &__text {
                @include text;
            }
        }
    }

    &__profitable {
        .home__stage {

            ._circle {
                top: 5%;
                left: 85%;

                &.-small{
                    left:0%;
                    top: 60%;
                }
            }
        }
    }

    &__content {

        .home__stage {
            z-index: 2;

            &:hover {
                ._semicircle {
                    &:nth-child(1) {
                        top: 0%;
                        right:10%;
                        transform: rotate(0);
                        width: 25vw;
                        height: 25vw;
                    }

                    &:nth-child(2) {
                        left:30%;
                        top: 60%;
                        transform: rotate(0deg);
                    }
                }
            }
            ._semicircle {
                @include trans(1s);

                &:nth-child(1) {
                    top: -5%;
                    right:-120%;
                    transform: rotate(180deg);
                    width: 25vw;
                    height: 25vw;
                }

                &:nth-child(2) {
                    left:-150%;
                    top: 90%;
                    transform: rotate(360deg);
                }
            }
        }

        .container {
            pointer-events: none;
        }

        &__title {
            @include mq(sm) {
                padding-top: 100px;
            }
        }
    }

    &__player {
        @include padd;
        margin-top: 80px;

        .container {
            pointer-events: none;
        }

        .home__stage {
            top: -80px;

            &:hover {
                ._semicircle {
                    &:nth-child(1) {
                        top: -10%;
                        left: -5%;
                        transform: rotate(-45deg);
                    }

                    &:nth-child(2) {
                        left:52%;
                        top: 75%;
                        transform: rotate(0);
                    }
                }
            }
            ._semicircle {
                @include trans(1s);

                &:nth-child(1) {
                    top: -15%;
                    left: -40%;
                    transform: rotate(-360deg);
                    width: 30vw;
                    height: 30vw;
                }

                &:nth-child(2) {
                    left:30%;
                    top: 120%;
                    transform: rotate(360deg);
                }
            }
        }

        text-align: center;

        @include mq(sm) {
            text-align: left;
        }

        &__title {
            @include section-title;
        }

        &__text {
            @include text;
        }

        &__phone {
            margin-top: -80px;
        }
    }

    &__started {
        @include gradient($pink, $purple);
        @include padd;

        &__title {
            @include section-title($color: $white);
        }

        &__text {
            @include text($color:$midGray);
        }

        &__form {
            padding-top: 20px;

            &__input {
                @include trans;
                background: rgba(0,0,0,.15);
                border:none;
                width: 100%;
                padding: 0 15px;
                height: 40px;
                line-height: 40px;
                margin-bottom: 20px;
                color: $midGray;

                &::placeholder {
                    color: $midGray;
                    opacity: 1;
                }

                &:focus {
                    outline:none;
                    box-shadow: none;
                    background: rgba(0,0,0,.3);
                }
            }

            &__checkbox {
                position: absolute;
                left: -9000;
                visibility: hidden;

                & + label {
                    position: relative;
                    padding-left: 40px;
                    line-height: 20px;
                    margin-top: 10px;
                    margin-bottom: 40px;
                    cursor: pointer;

                    @include mq(sm) {
                        margin-bottom: 0;
                    }

                    &:before {
                        @include trans;
                        content: '';
                        width: 20px;
                        height: 20px;
                        background: rgba(0,0,0,.15);
                        position: absolute;
                        left: 0;
                        top:0;
                    }

                    &:after {
                        @include trans;
                        content: '';
                        position: absolute;
                        left: 5px;
                        top:5px;
                        width: 10px;
                        height: 10px;
                        background: $white;
                        opacity: 0;
                        transform: scale3d(0,0,0);
                    }

                    a {
                        color: $midGray;
                        font-size: 14px;
                        font-weight: 300;
                    }

                    &:hover {

                        &:before {
                            background: rgba(0,0,0,.3);
                        }

                        a {
                            color: $white;
                        }
                    }
                }

                &:checked {
                    & + label {
                        &:after {
                            opacity: 1;
                            transform: scale3d(1,1,1);
                        }
                    }
                }
            }

            &__submit {
                width: 100%;
            }
        }
    }
}
